<template>
  <div id="multiple-sites-dashboard" class="site-container">
    <div v-if="isLoading" class="loading-screen">
      <div class="loading-container">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <div v-else class="summary-cost-saving">
      <div class="col-12">
        <div class="row">
          <div class="col-12 summary-col-5 summary-cost-saving--col">
            <div class="summary-cost-saving--wrapper">
              <div class="summary-cost-saving--content">
                 <div class="col-12">
                  <div class="row total-saving-row">
                    <div class="total-saving total-col-2 col-2">
                      <div class="total-saving-info">
                        <div class="total-saving-info-group">
                          <div class="info-img">
                            <img src="@/assets/pages/multiple-sites/summary-1.svg" />
                          </div>
                          <div class="info-desc">
                            <div class="info-data">
                              <strong class="info-num">{{ dataForm?.saving_summary?.total_saving ? convertBahtCurrency(dataForm.saving_summary.total_saving) : '0' }}</strong>
                              <span class="info-unit-more">{{ dataForm?.saving_summary?.total_saving ? convertCountingUnit(dataForm.saving_summary.total_saving) : '' }}</span>
                              <span class="info-currency">{{ "\u0e3f" }}</span>
                            </div>
                            <p class="info-total">Total saving</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="total-saving total-col-2 col-2">
                      <div class="total-saving-info">
                        <div class="total-saving-info-group">
                          <div class="info-img">
                            <img src="@/assets/pages/multiple-sites/summary-2.svg" />
                          </div>
                          <div class="info-desc">
                            <div class="info-data">
                              <strong class="info-num">{{ dataForm?.saving_summary?.total_co2_saving ? convertCo2Value(dataForm.saving_summary.total_co2_saving) : '0' }}</strong>
                              <span class="info-unit">{{ dataForm?.saving_summary?.total_co2_saving ? convertCo2Unit(dataForm.saving_summary.total_co2_saving) : 'kg' }}</span>
                            </div>
                            <p class="info-total">Total CO2 saving</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="total-saving total-col-2 col-2">
                      <div class="total-saving-info">
                        <div class="total-saving-info-group">
                          <div class="info-img">
                            <img src="@/assets/pages/multiple-sites/summary-3.svg" />
                          </div>
                          <div class="info-desc">
                            <div class="info-data">
                              <strong class="info-num">{{ dataForm && dataForm.site_summary ? dataForm.site_summary.total_site_installed : '' }}</strong>
                            </div>
                            <p class="info-total">Total site assigned</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="total-saving total-col-2 col-2">
                      <div class="total-saving-info">
                        <div class="total-saving-info-group">
                          <div class="info-img">
                            <img src="@/assets/pages/multiple-sites/summary-4.svg" />
                          </div>
                          <div class="info-desc">
                            <div class="info-data">
                              <strong class="info-num">{{ dataForm?.site_summary?.total_pv_installed ? convertPvValue(dataForm.site_summary.total_pv_installed) : '0' }}</strong>
                              <span class="info-unit">{{ dataForm?.site_summary?.total_pv_installed ? convertPvUnit(dataForm.site_summary.total_pv_installed) : 'kW' }}</span>
                            </div>
                            <p class="info-total">Total PV installed</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="total-saving total-col-2 col-2">
                      <div class="total-saving-info">
                        <div class="total-saving-info-group">
                          <div class="info-img">
                            <img src="@/assets/pages/multiple-sites/summary-5.svg" />
                          </div>
                          <div class="info-desc">
                            <div class="info-data">
                              <strong class="info-num">{{ dataForm && dataForm.site_summary ? dataForm.site_summary.total_evse_installed : '' }}</strong>
                            </div>
                            <p class="info-total">Total EVSEs installed</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="multi-sites-component">
      <MultiSitesInformation 
        v-if="multiSiteToggle" 
        :multiSiteToggle="multiSiteToggle" 
        @updateMultiSiteToggle="updateMultiSiteToggle" 
      />
      <MultiSitesDatatable 
        v-if="!multiSiteToggle" 
        :multiSiteToggle="multiSiteToggle" 
        @updateMultiSiteToggle="updateMultiSiteToggle" 
      />
    </div>
  </div>
</template> 

<style lang="scss" src="../assets/css/multiple-sites-dashboard.scss"></style>
<script>
import MultiSitesInformation from "@/components/MultiSitesInformation.vue";
import MultiSitesDatatable from "@/components/MultiSitesDatatable.vue";
import { multipleSitesService } from "../services";
export default {
  name: "MultipleSitesDashboard",
  components: { 
    MultiSitesInformation, 
    MultiSitesDatatable, 
  },
  data() {
    return {
      isLoading: true,
      multiSiteToggle: localStorage.getItem('toggle_sites_local_storage') === 'true' || false,
      dataForm: null,
    };
  },
  async created() {
    this.isLoading = true;
    if (this.$store.getters.isHeaderMenuFull) {
      this.$store.commit('setHeaderMenu', false);
    }
    this.getLocalTimeZone();

    await multipleSitesService
      .getMultipleSites()
      .then((res) => {
        if (res.status == 200 && res?.data?.data) {
          this.dataForm = res.data.data;
        } else {
          throw res;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.isLoading = false;
  },
  mounted() {},
  methods: {
    updateMultiSiteToggle(newValue) {
      this.multiSiteToggle = newValue;
      localStorage.setItem('toggle_sites_local_storage', newValue.toString());
    },
    getLocalTimeZone () {
      this.$store.commit('setTimeZone', null);
      localStorage.removeItem('selectedTimeZone');
    },
  },
  beforeDestroy() {
    localStorage.removeItem('toggle_sites_local_storage');
  },
};
</script>
