<template>
  <div class="general-datatable-wp">
    <v-row no-gutters>
      <v-col cols="12" class="general-datatable-content custom_scroll_data_table">
        <v-data-table
          :headers="headers"
          :items="dataForm.sites"
          :search="search"
          no-data-text="No Data"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-row no-gutters id="table-search" class="multi-table-search">
              <v-col cols="12" md="4" class="table-search-col-4">
                <div class="multiple-sites-total">
                  <div class="col-12 total-sites-chart-column">
                    <div class="alarm-summary-legend">
                      <div class="sites-legend">
                        <span class="circle green-circle"></span>
                        <div class="sites-legend-desc">
                          <strong class="legend-desc-num">{{ dataForm && dataForm.count ? dataForm.count.normal : '' }}</strong>
                          <p class="legend-desc-status">Normal <span class="status-percent">{{ dataForm?.status_percent ? formatDecimalNumber(dataForm.status_percent.normal) : '' }}%</span></p>
                        </div>
                      </div>
                      <div class="sites-legend">
                        <span class="circle yellow-circle"></span>
                        <div class="sites-legend-desc">
                          <strong class="legend-desc-num">{{ dataForm && dataForm.count ? dataForm.count.alarm : '' }}</strong>
                          <p class="legend-desc-status">Alarm <span class="status-percent">{{ dataForm?.status_percent ? formatDecimalNumber(dataForm.status_percent.alarm) : '' }}%</span></p>
                        </div>
                      </div>
                      <div class="sites-legend">
                        <span class="circle grey-circle"></span>
                        <div class="sites-legend-desc">
                          <strong class="legend-desc-num">{{ dataForm && dataForm.count ? dataForm.count.offline : '' }}</strong>
                          <p class="legend-desc-status">Offline <span class="status-percent">{{ dataForm?.status_percent ? formatDecimalNumber(dataForm.status_percent.offline) : '' }}%</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-spacer />
              <v-col cols="12" md="8" class="table-search-col-8">
                <div class="switch-component switch-custom">
                  <button 
                    :disabled="notAllowedActions()"
                    :class="`${notAllowedActions() ? 'not-allow-button' : ''}`" 
                    class="dialog-default-button data-table-add-size dialog-bg-grey-button add-site-btn add-user-btn button-hover" 
                    @click="openDialogAddProject"
                  >
                    Add New Project
                  </button>
                  <button
                    :disabled="notAllowedActions()"
                    :class="`${notAllowedActions() ? 'not-allow-button' : ''}`"
                    class="dialog-default-button data-table-add-size dialog-bg-grey-button add-site-btn add-user-btn button-hover" 
                    @click="openDialogAddSite"
                  >
                    Add New Site
                  </button>
                  <v-text-field
                    v-model="search"
                    placeholder="Search..."
                    class="search-multi-site"
                    prepend-inner-icon="fas fa-search"
                    rounded
                    outlined
                    filled
                  ></v-text-field>
                  <v-btn
                    class="multi-switch-btn button-hover"
                    icon
                    @click="openDialogFilterSite"
                    title="Filter Site"
                  >
                    <img src="@/assets/tables/filter-icon.svg" />
                  </v-btn>
                  <v-btn
                    class="multi-switch-btn map-switch button-hover multi-switch-size"
                    icon
                    @click="toggleMultiSite"
                  >
                    <img src="@/assets/pages/multiple-sites/switch-map.svg" />
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.devices`]="{ item }">
            <div class="d-flex justify-content-center">
              <v-btn
                :ripple="false"
                icon
                v-if="item.pv_installed"
                title="PV"
              >
                <img src="@/assets/tables/pv_icon_table.svg" />
              </v-btn>
              <v-btn
                :ripple="false"
                icon
                v-if="item.bess_installed"
                title="BESS"
              >
                <img src="@/assets/tables/battery_icon_table.svg" />
              </v-btn>
              <v-btn
                :ripple="false"
                icon
                v-if="item.evse_installed"
                title="EVSE"
              >
                <img src="@/assets/tables/evse_icon_table.svg" />
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <td 
              class="text-center" 
              :style="
                `background-color: ${SITE_STATUS_COLORS[item.status]};
                 color: ${item.status === 'offline' ? '#bebebe' : '#ffffff'}!important;
                `
              ">
                {{ capitalizeFirstLetter(item.status) }}
              </td>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-content-center">
              <v-btn
                :ripple="false"
                icon
                title="Edit Site"
                :disabled="notAllowedActions()"
                @click="openDialogEdit(item)"
              >
                <img 
                  class="icon-action" 
                  src="@/assets/tables/asset_edit.svg" />
              </v-btn>
              <template v-if="!item.deleted_at">
                <v-btn
                  :ripple="false"
                  icon
                  title="Delete Site"
                  :disabled="notAllowedActions()"
                  @click="openConfirmDialog(item, deleteSite, 'Delete Site', 'You are about to de-register this site. This site will no longer be available')"
                >
                  <img class="icon-action" src="@/assets/tables/asset_delete.svg" />
                </v-btn>
              </template>
              <router-link :to="{ path: `/single-site/${$store.state.siteId}` }">
                <v-btn
                  :ripple="false"
                  icon
                  title="Go To Site"
                  @click="saveSiteIdCurrent(item)"
                >
                  <img class="icon-action" src="@/assets/tables/angle_right.svg" />
                </v-btn>
              </router-link>
              <v-btn
                :ripple="false"
                icon
                :title="`${item?.site_config?.tou_enable ? 'Disable Tou' : 'Enable Tou'}`"
                :style="`opacity: ${item?.site_config?.tou_enable ? '' : '0.5'}`"
              >
                <img 
                  class="icon-action icon-action-size" 
                  src="@/assets/tables/tou_enable.jpg"
                  @click="handleChangeTou(item)" />
              </v-btn>
              <v-btn
                :ripple="false"
                icon
                :title="`${item?.site_config?.grid_export ? 'Disable Grid Export' : 'Enable Grid Export'}`"
                :style="`opacity: ${item?.site_config?.grid_export ? '' : '0.5'}`"
              >
                <img 
                  class="icon-action icon-action-size" 
                  src="@/assets/tables/grid_export_enable.jpg"
                  @click="handleChangeGrid(item)" />
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <div class="data-table-paging">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="5"
            :dark="true"
          ></v-pagination>
        </div>
      </v-col>
      <v-col cols="12" md="0" sm="0" />
    </v-row>
    <v-dialog
      v-model="dialogAddSite"
      persistent
      content-class="custom-content-modals custom-scroll-modals"
      max-width="756"
    >
      <v-card>
        <v-card-title>Add New Site</v-card-title>
        <v-card-text class="custom-asset-card">
          <v-form
            ref="addForm"
            @submit.prevent="submitAdd"
            autocomplete="off"
          >
            <div class="scroll_dialog_form">
              <v-row no-gutters>
                <v-col cols="12" md="3" class="modal-label">
                  <span>Global</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listGlobals"
                    v-model="siteGlobal"
                    :rules="siteGlobalRules"
                    item-text="name"
                    item-value="id"
                    :class="`${siteGlobal ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Global"
                    @change="handleChangeGlobal(siteGlobal)"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Regional</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listRegionals"
                    v-model="siteRegional"
                    :rules="siteRegionalRules"
                    item-text="name"
                    item-value="id"
                    :class="`${siteRegional ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Regional"
                    @change="handleChangeRegional(siteRegional)"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Area</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listAreas"
                    v-model="siteArea"
                    :rules="siteAreaRules"
                    item-text="name"
                    item-value="id"
                    :class="`${siteArea ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Area"
                    @change="handleChangeArea(siteArea)"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Project</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listProjects"
                    v-model="siteProject"
                    :rules="siteProjectRules"
                    item-text="name"
                    item-value="id"
                    :class="`${siteProject ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Project"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Unit</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="siteUnit"
                    required
                    autocomplete="off"
                    :rules="siteUnitRules"
                    placeholder="Enter Unit"
                    filled
                    :validate-on-blur="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2 autocomplete-wrapper">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Address</span>
                </v-col>
                <v-col cols="12" md="9">
                  <gmap-autocomplete
                    :value="siteAddress"
                    id="site_address"
                    class="form-control"
                    placeholder="Enter Address"
                    @place_changed="getAddressData($event)"
                  />
                  <div v-if="!validAddress" class="invalid-address">
                    <p class="message-content">Address is required</p>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Site ID</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    required
                    autocomplete="off"
                    placeholder="(Auto Generate)"
                    filled
                    :validate-on-blur="true"
                    class="custom_read_only_input"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Site Name</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="siteName"
                    required
                    autocomplete="off"
                    :rules="siteNameRules"
                    placeholder="Enter Site Name"
                    filled
                    :validate-on-blur="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Currency</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="LIST_CURRENCIES"
                    v-model="siteCurrency"
                    :rules="siteCurrencyRules"
                    item-text="name"
                    item-value="symbol"
                    :class="`${siteCurrency ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections disabled_input"
                    required
                    filled
                    :menu-props="{ contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Currency"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item class="customize_v-list-item">
                        <v-list-item-content>
                          <v-text-field v-model="searchCurrencyTerm" placeholder="Search Currency" @input="searchCurrency"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row> -->
              <!-- <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Site Image</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-file-input
                    v-model="siteImage"
                    :rules="siteImageRules"
                    placeholder="Choose Site Image"
                    prepend-icon=""
                    clearable
                    class="custom_file_icon custom_cursor_pointer"
                  ></v-file-input>
                </v-col>
              </v-row> -->
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Assign Installer</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="assignInstallersList"
                    v-model="assignInstaller"
                    :rules="assignInstallerRules"
                    item-text="username"
                    item-value="id"
                    :class="`${assignInstaller.length ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections disabled_input"
                    required
                    filled
                    :menu-props="{ top: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Installer"
                    multiple
                    chips
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2">
                        <span>{{ item.username }}</span>
                      </v-chip>
                      <span
                        v-if="index === 2"
                        class="text-grey text-caption align-self-center"
                      >
                        (+{{ assignInstaller.length - 2 }} others)
                      </span>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item class="customize_v-list-item">
                        <v-list-item-content>
                          <v-text-field v-model="searchTerm" placeholder="Search Installer" @input="searchInstallers"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </div>
            <v-row no-gutters class="mt-3 pt-2">
              <v-spacer></v-spacer>
              <v-col cols="12" class="text-center">
                <button
                  class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                  :ripple="false"
                  type="button"
                  @click="closeDialogAddSite"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                  :ripple="false"
                  type="submit"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogAddProject"
      persistent
      content-class="custom-content-modals"
      max-width="756"
    >
      <v-card>
        <v-card-title>Add New Project</v-card-title>
        <v-card-text class="custom-asset-card">
          <v-form
            ref="addProjectForm"
            @submit.prevent="submitAddProject"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="12" md="3" class="modal-label">
                <span>Global</span>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :items="listGlobals"
                  v-model="siteGlobal"
                  :rules="siteGlobalRules"
                  item-text="name"
                  item-value="id"
                  :class="`${siteGlobal ? 'custom-hidden-input' : ''}`"
                  class="custom-select__selections"
                  required
                  filled
                  :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                  append-icon="fas fa-caret-down"
                  placeholder="Select Global"
                  @change="handleChangeGlobal(siteGlobal)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="3" class="modal-label">
                <span>Regional</span>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :items="listRegionals"
                  v-model="siteRegional"
                  :rules="siteRegionalRules"
                  item-text="name"
                  item-value="id"
                  :class="`${siteRegional ? 'custom-hidden-input' : ''}`"
                  class="custom-select__selections"
                  required
                  filled
                  :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                  append-icon="fas fa-caret-down"
                  placeholder="Select Regional"
                  @change="handleChangeRegional(siteRegional)"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="3" class="modal-label">
                <span>Area</span>
              </v-col>
              <v-col cols="12" md="9">
                <v-select
                  :items="listAreas"
                  v-model="siteArea"
                  :rules="siteAreaRules"
                  item-text="name"
                  item-value="id"
                  :class="`${siteArea ? 'custom-hidden-input' : ''}`"
                  class="custom-select__selections"
                  required
                  filled
                  :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                  append-icon="fas fa-caret-down"
                  placeholder="Select Area"
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pt-2">
              <v-col cols="12" md="3" class="modal-label">
                <span>Project</span>
              </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  v-model="projectName"
                  required
                  autocomplete="off"
                  :rules="projectRules"
                  placeholder="Enter Project"
                  filled
                  :validate-on-blur="true"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-3 pt-2">
              <v-spacer></v-spacer>
              <v-col cols="12" class="text-center">
                <button
                  class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                  :ripple="false"
                  type="button"
                  @click="closeDialogAddProject"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                  :ripple="false"
                  type="submit"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEditSite"
      persistent
      content-class="custom-content-modals custom-scroll-modals"
      max-width="756"
    >
      <v-card>
        <v-card-title>Edit Site</v-card-title>
        <v-card-text class="custom-asset-card">
          <v-form
            ref="editForm"
            @submit.prevent="submitEdit"
            autocomplete="off"
          >
            <div class="scroll_dialog_form">
              <v-row no-gutters style="pointer-events: none;">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Global</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listGlobals"
                    v-model="editSiteGlobal"
                    :rules="siteGlobalRules"
                    item-text="name"
                    item-value="id"
                    :class="`${editSiteGlobal ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Global"
                    @change="handleChangeGlobalEdit(editSiteGlobal)"
                    disabled
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2" style="pointer-events: none;">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Regional</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listRegionals"
                    v-model="editSiteRegional"
                    :rules="siteRegionalRules"
                    item-text="name"
                    item-value="id"
                    :class="`${editSiteRegional ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Regional"
                    @change="handleChangeRegionalEdit(editSiteRegional)"
                    disabled
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2" style="pointer-events: none;">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Area</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listAreas"
                    v-model="editSiteArea"
                    :rules="siteAreaRules"
                    item-text="name"
                    item-value="id"
                    :class="`${editSiteArea ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Area"
                    @change="handleChangeAreaEdit(editSiteArea)"
                    disabled
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2" style="pointer-events: none;">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Project</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="listProjects"
                    v-model="editSiteProject"
                    :rules="siteProjectRules"
                    item-text="name"
                    item-value="id"
                    :class="`${editSiteProject ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections"
                    required
                    filled
                    :menu-props="{ bottom: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Project"
                    disabled
                  ></v-select>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2" style="pointer-events: none;">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Unit</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="editSiteUnit"
                    required
                    autocomplete="off"
                    :rules="siteUnitRules"
                    placeholder="Enter Unit"
                    filled
                    :validate-on-blur="true"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2 autocomplete-wrapper" style="pointer-events: none;">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Address</span>
                </v-col>
                <v-col cols="12" md="9">
                  <gmap-autocomplete
                    :value="editSiteAddress"
                    id="site_address"
                    class="form-control"
                    placeholder="Enter Address"
                    @place_changed="getAddressDataEdit($event)"
                    disabled
                  />
                  <div v-if="!validAddress" class="invalid-address">
                    <p class="message-content">Address is required</p>
                  </div>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Site ID</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="editSiteId"
                    required
                    autocomplete="off"
                    placeholder="(Auto Generate)"
                    filled
                    :validate-on-blur="true"
                    class="custom_read_only_input"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Site Name</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-text-field
                    v-model="editSiteName"
                    required
                    autocomplete="off"
                    :rules="siteNameRules"
                    placeholder="Enter Site Name"
                    filled
                    :validate-on-blur="true"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Currency</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="LIST_CURRENCIES"
                    v-model="editSiteCurrency"
                    :rules="siteCurrencyRules"
                    item-text="name"
                    item-value="symbol"
                    :class="`${editSiteCurrency ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections disabled_input"
                    required
                    filled
                    :menu-props="{ contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Currency"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item class="customize_v-list-item">
                        <v-list-item-content>
                          <v-text-field v-model="searchCurrencyTerm" placeholder="Search Currency" @input="searchCurrency"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row> -->
              <!-- <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Site Image</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-file-input
                    v-model="editSiteImage"
                    :rules="editsSiteImageRules"
                    placeholder="Choose Site Image"
                    prepend-icon=""
                    clearable
                    class="custom_file_icon custom_cursor_pointer"
                  ></v-file-input>
                </v-col>
              </v-row> -->
              <v-row no-gutters class="pt-2">
                <v-col cols="12" md="3" class="modal-label">
                  <span>Assign Installer</span>
                </v-col>
                <v-col cols="12" md="9">
                  <v-select
                    :items="assignInstallersList"
                    v-model="editAssignInstaller"
                    :rules="assignInstallerRules"
                    item-text="username"
                    item-value="id"
                    :class="`${editAssignInstaller.length ? 'custom-hidden-input' : ''}`"
                    class="custom-select__selections disabled_input"
                    required
                    filled
                    :menu-props="{ top: true, offsetY: true, contentClass: 'custom_active_item' }"
                    append-icon="fas fa-caret-down"
                    placeholder="Select Installer"
                    multiple
                    chips
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 2">
                        <span>{{ item.username }}</span>
                      </v-chip>
                      <span
                        v-if="index === 2"
                        class="text-grey text-caption align-self-center"
                      >
                        (+{{ editAssignInstaller.length - 2 }} others)
                      </span>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item class="customize_v-list-item">
                        <v-list-item-content>
                          <v-text-field v-model="searchTerm" placeholder="Search Installer" @input="searchInstallers"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </div>
            <v-row no-gutters class="mt-3 pt-2">
              <v-spacer></v-spacer>
              <v-col cols="12" class="text-center">
                <button
                  class="dialog-default-button dialog-button-add-size dialog-bg-outline-button"
                  :ripple="false"
                  type="button"
                  @click="closeDialogEditSite"
                >
                  CANCEL
                </button>
                <button
                  class="ml-4 dialog-default-button dialog-button-add-size dialog-bg-grey-button"
                  :ripple="false"
                  type="submit"
                >
                  CONFIRM
                </button>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogFilterSite"
      persistent
      content-class="custom-content-modals"
      max-width="1019"
    >
      <v-card>
        <v-card-title class="v-card__title-filter">Filter</v-card-title>
        <v-card-text class="custom-asset-card v-card__text-filter">
          <v-form
            ref="filterSiteForm"
            @submit.prevent="submitFilter"
            autocomplete="off"
          >
            <v-row no-gutters>
              <v-col cols="5" md="5" class="filter-col-5">
                <p class="filter-name text-light mb-3">Global/Regional/Area/Project</p>
                <div class="menu-tree-wrapper">
                  <div class="tree-node text-light" v-for="(globalItem, index) in listGlobalsFilter" :key="index">
                    <div
                      class="tree-node-content"
                      :id="'toggle-' + index"
                    >
                      <div 
                        v-if="globalItem.regionals.length" 
                        class="icons-wrap" 
                        v-b-toggle="'collapse-' + index" 
                        @click="renderCollapseIcon(globalItem)"
                      >
                        <span v-show="globalItem.collapse">
                          <i class="fas fa-minus"></i>
                        </span>
                        <span v-show="!globalItem.collapse">
                          <i class="fas fa-plus"></i>
                        </span>
                      </div>
                      <div class="option-item mb-0">
                        <input v-model="globalItem.isChecked" class="cursor-pointer" type="checkbox" :id="'input-' + index" :name="'input-' + index" :value="globalItem.name" />
                        <label :for="'input-' + index">{{ globalItem.name }}</label>
                      </div>
                    </div>
                    <b-collapse :id="'collapse-' + index" @shown="handleCollapseChecked(globalItem)">
                      <div class="tree-node">
                        <div v-for="(regionalItem, subIndex) in globalItem.regionals" class="tree-node" :key="subIndex">
                          <div
                            class="tree-node-content"
                            :id="'toggle-' + index + '-' + subIndex"
                          >
                            <div 
                              v-if="regionalItem.areas.length" 
                              class="icons-wrap" 
                              v-b-toggle="'collapse-' + index + '-' + subIndex" 
                              @click="renderCollapseIcon(regionalItem)"
                            >
                              <span v-show="regionalItem.collapse">
                                <i class="fas fa-minus"></i>
                              </span>
                              <span v-show="!regionalItem.collapse">
                                <i class="fas fa-plus"></i>
                              </span>
                            </div>
                            <div class="option-item mb-0">
                              <input v-model="regionalItem.isChecked" class="cursor-pointer" type="checkbox" :id="'input-' + index + '-' + subIndex" :name="'input-' + index + '-' + subIndex" :value="regionalItem.name" />
                              <label :for="'input-' + index + '-' + subIndex">{{ regionalItem.name }}</label>
                            </div>
                          </div>
                          <b-collapse :id="'collapse-' + index + '-' + subIndex" @shown="handleCollapseChecked(regionalItem)">
                            <div class="tree-node">
                              <div v-for="(areaItem, subSubIndex) in regionalItem.areas" class="tree-node" :key="subSubIndex">
                                <div
                                  class="tree-node-content"
                                  :id="'toggle-' + index + '-' + subIndex + '-' + subSubIndex"
                                >
                                  <div 
                                    v-if="areaItem.projects.length" 
                                    class="icons-wrap" 
                                    v-b-toggle="'collapse-' + index + '-' + subIndex + '-' + subSubIndex" 
                                    @click="renderCollapseIcon(areaItem)"
                                  >
                                    <span v-show="areaItem.collapse">
                                      <i class="fas fa-minus"></i>
                                    </span>
                                    <span v-show="!areaItem.collapse">
                                      <i class="fas fa-plus"></i>
                                    </span>
                                  </div>
                                  <div class="option-item mb-0">
                                    <input v-model="areaItem.isChecked" class="cursor-pointer" type="checkbox" :id="'input-' + index + '-' + subIndex + '-' + subSubIndex" :name="'input-' + index + '-' + subIndex + '-' + subSubIndex" :value="areaItem.name" />
                                    <label :for="'input-' + index + '-' + subIndex + '-' + subSubIndex">{{ areaItem.name }}</label>
                                  </div>
                                </div>
                                <b-collapse :id="'collapse-' + index + '-' + subIndex + '-' + subSubIndex" @shown="handleCollapseChecked(areaItem)">
                                  <div class="tree-node">
                                    <div v-for="(projectItem, subSubSubIndex) in areaItem.projects" class="tree-node" :key="subSubSubIndex">
                                      <div 
                                        class="tree-node-content"
                                        :id="'toggle-' + index + '-' + subIndex + '-' + subSubIndex + '-' + subSubSubIndex"
                                      >
                                        <div class="option-item mb-0">
                                          <input v-model="projectItem.isChecked" class="cursor-pointer" type="checkbox" :id="'input-' + index + '-' + subIndex + '-' + subSubIndex + '-' + subSubSubIndex" :name="'input-' + index + '-' + subIndex + '-' + subSubIndex + '-' + subSubSubIndex" :value="projectItem.name" />
                                          <label :for="'input-' + index + '-' + subIndex + '-' + subSubIndex + '-' + subSubSubIndex">{{ projectItem.name }}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </b-collapse>
                              </div>
                            </div>
                          </b-collapse>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </v-col>
              <v-col cols="7" md="7" class="filter-col-7">
                <v-row no-gutters>
                  <v-col cols="12" md="12" class="text-light">
                    <p class="filter-name">Installed DER</p>
                    <v-row no-gutters class="checkboxes-row">
                      <v-col cols="4">
                        <div class="option-item">
                          <input v-model="selectedInstalledDer" class="cursor-pointer" type="checkbox" id="pv-installed" name="pv-installed" value="pv_installed" />
                          <label for="pv-installed">PV Inverter</label>
                        </div>
                      </v-col>

                      <v-col cols="4">
                        <div class="option-item">
                          <input v-model="selectedInstalledDer" class="cursor-pointer" type="checkbox" id="bess-installed" name="bess-installed" value="bess_installed" />
                          <label for="bess-installed">BESS</label>
                        </div>
                      </v-col>

                      <v-col cols="4">
                        <div class="option-item">
                          <input v-model="selectedInstalledDer" class="cursor-pointer" type="checkbox" id="evse-installed" name="evse-installed" value="evse_installed" />
                          <label for="evse-installed">EVSE</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12" class="text-light">
                    <p class="filter-name">Site Status</p>
                    <v-row no-gutters class="checkboxes-row">
                      <v-col cols="4">
                        <div class="option-item">
                          <input v-model="selectedSiteStatuses" class="cursor-pointer" type="checkbox" id="normal-status" name="normal-status" value="normal" />
                          <label for="normal-status">Normal</label>
                        </div>
                      </v-col>

                      <v-col cols="4">
                        <div class="option-item">
                          <input v-model="selectedSiteStatuses" class="cursor-pointer" type="checkbox" id="alarm-status" name="alarm-status" value="alarm" />
                          <label for="alarm-status">Alarm</label>
                        </div>
                      </v-col>

                      <v-col cols="4">
                        <div class="option-item">
                          <input v-model="selectedSiteStatuses" class="cursor-pointer" type="checkbox" id="offline-status" name="offline-status" value="offline" />
                          <label for="offline-status">Offline</label>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="12" class="text-light">
                    <p class="filter-name">Commissioned Date</p>
                    <v-row no-gutters class="checkboxes-row commissioned-date-row">
                      <v-col cols="1" md="1" class="date-picker-title">
                        <p>From</p>
                      </v-col>
                      <v-col cols="3" md="3" class="date-picker-table">
                        <v-menu
                          ref="commissionedDateMenu"
                          v-model="commissionedDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="332"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="commissionedDateTextFrom"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              append-icon="fas fa-caret-down"
                              class="date-picker-text-field"
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            flat
                            v-model="commissionedDateRange"
                            no-title
                            range
                            width="auto"
                            first-day-of-week="0"
                            type="date"
                            :min="rangeMinDate"
                            :max="rangeMaxDate"
                            :dark="true"
                            class="customize_commissioned_date_picker"
                            @input="closeCommissionedDateMenu"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="1" md="1" class="date-picker-title">
                        <p style="margin-left: 13px;">To</p>
                      </v-col>
                      <v-col cols="3" md="3" class="date-picker-table">
                        <v-text-field
                          v-model="commissionedDateTextTo"
                          readonly
                          append-icon="fas fa-caret-down"
                          class="date-picker-text-field"
                          @click="openCommissionedDateMenu"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters class="filter-buttons-row">
                  <v-spacer></v-spacer>
                  <v-col cols="12" class="text-center">
                    <button
                      class="dialog-default-button button-filter-site-size dialog-bg-outline-button"
                      :ripple="false"
                      type="button"
                      @click="closeDialogFilterSite"
                    >
                      CANCEL
                    </button>
                    <button
                      class="ml-4 dialog-default-button button-filter-site-size dialog-bg-outline-button"
                      :ripple="false"
                      type="button"
                      @click="resetDialogFilterSite"
                    >
                      RESET
                    </button>
                    <button
                      class="ml-4 dialog-default-button button-filter-site-size dialog-bg-grey-button"
                      :ripple="false"
                      type="submit"
                    >
                      APPLY
                    </button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="messageDialog"
      max-width="500"
      content-class="confirm-dialog-box"
    >
      <v-card>
        <v-card-title class="justify-content-center">{{ apiTitle }}</v-card-title>
        <v-card-text
          ><v-row no-gutters>
            <v-col cols="12" class="text-center">
              <span v-html="apiMessage"></span>
            </v-col>
            <v-col cols="12" class="dialog-buttons-padding text-center">
              <button
                class="dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                type="button"
                :ripple="false"
                @click="closeMessage"
              >
                OK
              </button>
            </v-col>
          </v-row></v-card-text
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      persistent
      max-width="672"
      content-class="confirm-dialog-box"
    >
      <v-card>
        <v-card-title>{{ dialogHeading }}</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="dialog-content">
              <span>{{ dialogMessage }}</span>
            </v-col>
            <v-col cols="12" class="dialog-buttons-padding text-center">
              <button
                class="dialog-default-button dialog-button-confirm-size dialog-bg-outline-button"
                type="button"
                :ripple="false"
                @click="closeConfirmDialog"
              >
                CANCEL
              </button>
              <button
                class="ml-4 dialog-default-button dialog-button-confirm-size dialog-bg-grey-button"
                type="button"
                :ripple="false"
                @click="dialogAction(siteItem)"
              >
                CONFIRM
              </button>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss" src="@/assets/css/general-datatable-page.scss"></style>
<script>
import { siteService, multipleSitesService } from "@/services";
import moment from "moment";
export default {
  props: ['multiSiteToggle',],
  data() {
    return {
      search: "",
      messageDialog: false,
      apiMessage: "",
      apiTitle: "",
      headers: [
        { text: "Site ID", value: "id", width: 110, align: 'center' },
        { text: "Site Name", value: "site_name", sortable: false, align: 'center', class: 'custom_name_width' },
        { text: "Global", value: "global", width: 124, sortable: false, align: 'center' },
        { text: "Regional", value: "regional", width: 142, sortable: false, align: 'center' },
        { text: "Area", value: "area", width: 108, sortable: false, align: 'center' },
        { text: "Project", value: "project", sortable: false, align: 'center' },
        { text: "Address", value: "address", sortable: false, width: 314, align: 'center' },
        { text: "Unit", value: "unit", sortable: false, align: 'center' },
        { text: "Devices", value: "devices", sortable: false, align: 'center' },
        { text: "Commissioned Date", value: "commissioned_date", align: 'center', class: 'custom_commissioned_width' },
        { text: "Site Status", value: "status", align: 'center', class: 'custom_status_width' },
        { text: "Action", value: "actions", sortable: false, align: 'center', filterable: false },
      ],
      dataForm: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialogAddSite: false,
      confirmDialog: false,
      siteItem: {},
      dialogAction: () => null,
      dialogHeading: "",
      dialogMessage: "",
      listGlobals: [],
      listGlobalsFilter: [],
      listRegionals: [],
      listAreas: [],
      listProjects: [],
      siteGlobal: "",
      siteRegional: "",
      siteArea: "",
      siteProject: "",
      siteUnit: "",
      siteAddress: "",
      latitude: null,
      longitude: null,
      timeZone: null,
      // siteCurrency: "",
      // siteImage: null,
      siteName: "",
      assignInstallersList: [],
      assignInstaller: [],
      dialogEditSite: false,
      editSiteGlobal: "",
      editSiteRegional: "",
      editSiteArea: "",
      editSiteProject: "",
      editSiteUnit: "",
      editSiteAddress: "",
      editSiteId: "",
      editSiteName: "",
      editAssignInstaller: [],
      editLatitude: null,
      editLongitude: null,
      // editSiteCurrency: "",
      // editSiteImage: null,
      validAddress: true,
      dialogFilterSite: false,
      selectedSiteStatuses: [],
      commissionedDateMenu: false,
      commissionedDateRange: [],
      selectedInstalledDer: [],
      selectedGlobals: [],
      selectedRegionals: [],
      selectedAreas: [],
      selectedProjects: [],
      dialogAddProject: false,
      projectName: "",
      siteGlobalRules: [
        (v) => !!v || "Global is required",
      ],
      siteRegionalRules: [
        (v) => !!v || "Regional is required",
      ],
      siteAreaRules: [
        (v) => !!v || "Area is required",
      ],
      siteProjectRules: [
        (v) => !!v || "Project is required",
      ],
      projectRules: [
        (v) => !!v || "Project is required",
        (v) => (v && v.length <= 50) || "Project must be at most 50 characters",
      ],
      // siteCurrencyRules: [
      //   (v) => !!v || "Currency is required",
      // ],
      // siteImageRules: [
      //   (v) => !!v || "Site Image is required",
      //   (v) => !v || ['image/jpeg', 'image/png', 'image/jpg'].includes(v.type) || "Only jpeg, png or jpg files are allowed",
      //   (v) => !v || (v.size / 1024 / 1024) <= 2 || "Site Image size must be less than 2MB",
      // ],
      // editsSiteImageRules: [
      //   (v) => !v || ['image/jpeg', 'image/png', 'image/jpg'].includes(v.type) || "Only jpeg, png or jpg files are allowed",
      //   (v) => !v || (v.size / 1024 / 1024) <= 2 || "Site Image size must be less than 2MB",
      // ],
      siteUnitRules: [
        (v) => !!v || "Unit is required",
      ],
      siteNameRules: [
        (v) => !!v || "Site Name is required",
        (v) => (v && v.length <= 50) || "Site Name must be at most 50 characters",
      ],
      assignInstallerRules: [
        (v) =>!!v.length || "Assign Installer is required",
      ],
      searchTerm: "",
      // searchCurrencyTerm: "",
      installersListCopy: [],
      currenciesListCopy: [],
    };
  },
  async created() {
    await this.getMultipleSites();
    await this.getListGlobals();
    await this.getListAssignInstallers();
    this.addFieldsMenuTree();
    if (this.assignInstallersList?.length) {
      this.installersListCopy = [...this.assignInstallersList];
    }
    if (this.LIST_CURRENCIES?.length) {
      this.currenciesListCopy = [...this.LIST_CURRENCIES];
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.user;
    },
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
    commissionedDateTextFrom () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 0){
        return `${this.commissionedDateRange[0] ? moment(this.commissionedDateRange[0]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    commissionedDateTextTo () {
      if(this.commissionedDateRange && this.commissionedDateRange.length > 1){
        return `${this.commissionedDateRange[1] ? moment(this.commissionedDateRange[1]).format("DD/MM/YYYY") : ''}`;
      }
      return null;
    },
    rangeMinDate() {
      const minDate = this.commissionedDateRange?.length === 1 ? this.commissionedDateRange[0] : null;
      return minDate;
    },
    rangeMaxDate() {
      const maxDate = this.NOW_DATE().format(this.DATE_PICKER_FORMAT);
      return maxDate;
    },
  },
  methods: {
    async getMultipleSites(filters) {
      await multipleSitesService.getMultipleSites(filters)
        .then((res) => {
          if (res.status == 200 && res?.data?.data) {
            const data = res.data.data;
            if (data?.sites?.length) {
              data.sites.forEach(site => {
                site.commissioned_date = site?.commissioned_date ? this.MOMENT(site.commissioned_date, 'DD-MM-YYYY') : "−−";
              });
            }
            this.dataForm = {...data};
          } else {
            throw res;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleMultiSite() {
      const newValue = !this.multiSiteToggle;
      this.$emit('updateMultiSiteToggle', newValue);
    },
    saveSiteIdCurrent(item) {
      this.$store.commit('setSiteId', item.id);
      this.$store.commit('setTimeZone', item.timezone);
      // this.$store.commit('setSiteCurrency', item.currency);
      localStorage.setItem('selectedSiteId', this.$store.state.siteId);
      localStorage.setItem('selectedTimeZone', this.$store.state.time_zone);
      // localStorage.setItem('selectedSiteCurrency', this.$store.state.currency);
    },
    openDialogAddSite() {
      this.dialogAddSite = true;
    },
    closeDialogAddSite() {
      this.revertSite();
      this.dialogAddSite = false;
    },
    async submitAdd() {
      this.checkValidAddress("add");
      if (this.$refs.addForm.validate() && this.validAddress) {
        let data = new FormData();
        data.append("name", this.siteName);
        data.append("assign_installer", JSON.stringify(this.assignInstaller));
        data.append("project_id", this.siteProject);
        data.append("unit", this.siteUnit);
        data.append("address", this.siteAddress);
        data.append("latitude", this.latitude);
        data.append("longitude", this.longitude);
        data.append("timezone", this.timeZone);
        // data.append("currency", this.siteCurrency);
        // data.set("image", this.siteImage);
        
        await siteService
          .storeSite(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getMultipleSites();
            this.closeDialogAddSite();
            this.apiMessage = "Site has been successfully added";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Site cannot be added";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    revertSite() {
      this.$refs.addForm.reset();
      this.siteAddress = "";
      this.timeZone = null;
      this.longitude = null;
      this.latitude = null;
    },
    closeMessage() {
      this.messageDialog = false;
    },
    deleteSite(item) {
      if (item?.id) {
        siteService
          .deleteSite(item.id)
          .then((res) => {
            if (res.status !== 200 && res.status !== 204) throw res;
            this.closeConfirmDialog();
            this.getMultipleSites();
            this.apiMessage = "Site has been successfully deleted";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Site cannot be deleted";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    openConfirmDialog(item, action, heading, message) {
      this.siteItem = Object.assign({}, this.siteItem, item);
      this.dialogAction = action;
      this.dialogHeading = heading;
      this.dialogMessage = message;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.siteItem = Object.assign({}, this.siteItem);
      this.confirmDialog = false;
      this.dialogAction = () => null;
      this.dialogHeading = "";
      this.dialogMessage = "";
    },
    getListGlobals() {
      return multipleSitesService
        .getListGlobals()
        .then((res) => {
          if (res?.data?.data) {
            const dataSiteGlobals = res.data.data;
            this.listGlobals = [...dataSiteGlobals];
            this.listGlobalsFilter = [...dataSiteGlobals];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    handleChangeGlobal(global_id) {
      this.listRegionals = [];
      this.siteRegional = "";
      this.listAreas = [];
      this.siteArea = "";
      this.listProjects = [];
      this.siteProject = "";
      const selectedGlobal = this.listGlobals.find(global => global.id === global_id);
      if (selectedGlobal) {
        this.listRegionals = selectedGlobal.regionals;
      }
    },
    handleChangeRegional(regional_id) {
      this.listAreas = [];
      this.siteArea = "";
      this.listProjects = [];
      this.siteProject = "";
      const selectedRegional = this.listRegionals.find(regional => regional.id === regional_id);
      if (selectedRegional) {
        this.listAreas = selectedRegional.areas;
      }
    },
    handleChangeArea(area_id) {
      this.listProjects = [];
      this.siteProject = "";
      const selectedArea = this.listAreas.find(area => area.id === area_id);
      if (selectedArea) {
        this.listProjects = selectedArea.projects;
      }
    },
    async getListAssignInstallers() {
      return await siteService
        .getListAssignInstallers()
        .then((res) => {
          if (res?.data?.data) {
            const dataAssignInstallers = res.data.data;
            this.assignInstallersList = [...dataAssignInstallers];
            return res;
          }
        })
        .catch(() => {
          return null;
        });
    },
    setSiteInfo(data) {
      if (data) {
        this.editSiteGlobal = data.global_id || "";
        this.handleChangeGlobalEdit(this.editSiteGlobal);
        this.editSiteRegional = data.regional_id || "";
        this.handleChangeRegionalEdit(this.editSiteRegional);
        this.editSiteArea = data.area_id || "";
        this.handleChangeAreaEdit(this.editSiteArea);
        this.editSiteProject = data.project_id || "";
        this.editSiteUnit = data.unit || "";
        this.editSiteAddress = data.address || "";
        this.editLatitude = parseFloat(data.latitude) || null;
        this.editLongitude = parseFloat(data.longitude) || null;
        this.editSiteId = data.id || "";
        this.editSiteName = data.name || "";
        // this.editSiteCurrency = data.currency || "";
        this.editAssignInstaller = data.assign_installer || [];
        this.siteInfo = data;
      } else {
        this.editSiteGlobal = "";
        this.editSiteRegional = "";
        this.editSiteArea = "";
        this.editSiteProject = "";
        this.editSiteUnit = "";
        this.editSiteAddress = "";
        this.editLatitude = null;
        this.editLongitude = null;
        this.editSiteId = "";
        this.editSiteName = "";
        // this.editSiteCurrency = "";
        this.editAssignInstaller = [];
        this.siteInfo = null;
      }
    },
    openDialogEdit(item) {
      if (item?.id) {
        siteService
          .getSite(item.id)
          .then((res) => {
            if (res?.data?.data) {
              this.setSiteInfo(res.data.data);
              this.dialogEditSite = true;
            }
          })
          .catch();
      }
    },
    closeDialogEditSite() {
      this.setSiteInfo(null);
      this.revertSiteEdit();
      this.dialogEditSite = false;
    },
    revertSiteEdit() {
      this.$refs.editForm.reset();
    },
    submitEdit() {
      this.checkValidAddress("edit");
      if (this.$refs.editForm.validate() && this.validAddress) {
        let data = new FormData();
        data.append("name", this.editSiteName);
        data.append("assign_installer", JSON.stringify(this.editAssignInstaller));
        // data.append("currency", this.editSiteCurrency);
        // if(this.editSiteImage) {
        //   data.set("image", this.editSiteImage);
        // }

        siteService
          .updateSite(this.siteInfo.id, data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.apiMessage = "Site's information has been successfully updated";
            this.apiTitle = "Success";
            this.messageDialog = true;
            this.getMultipleSites();
            this.closeDialogEditSite();
          })
          .catch((err) => {
            let message = "Site's information cannot be updated";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    handleChangeGlobalEdit(global_id) {
      this.listRegionals = [];
      this.editSiteRegional = "";
      this.listAreas = [];
      this.editSiteArea = "";
      this.listProjects = [];
      this.editSiteProject = "";
      const selectedGlobal = this.listGlobals.find(global => global.id === global_id);
      if (selectedGlobal) {
        this.listRegionals = selectedGlobal.regionals;
      }
    },
    handleChangeRegionalEdit(regional_id) {
      this.listAreas = [];
      this.editSiteArea = "";
      this.listProjects = [];
      this.editSiteProject = "";
      const selectedRegional = this.listRegionals.find(regional => regional.id === regional_id);
      if (selectedRegional) {
        this.listAreas = selectedRegional.areas;
      }
    },
    handleChangeAreaEdit(area_id) {
      this.listProjects = [];
      this.editSiteProject = "";
      const selectedArea = this.listAreas.find(area => area.id === area_id);
      if (selectedArea) {
        this.listProjects = selectedArea.projects;
      }
    },
    getAddressData(place) {
      if (place && place.geometry && place.geometry.location) {
        this.latitude = parseFloat(place.geometry.location.lat());
        this.longitude = parseFloat(place.geometry.location.lng());
        this.siteAddress = place.formatted_address;
        this.timeZone = place.utc_offset_minutes ? moment().utcOffset(place.utc_offset_minutes).format('UTCZ') : "UTC+00:00";
        this.checkValidAddress("add");
      }
    },
    getAddressDataEdit(place) {
      if (place && place.geometry && place.geometry.location) {
        this.editLatitude = parseFloat(place.geometry.location.lat());
        this.editLongitude = parseFloat(place.geometry.location.lng());
        this.editSiteAddress = place.formatted_address;
        this.checkValidAddress("edit");
      }
    },
    checkValidAddress(type) {
      if(type === "edit" && (!this.editSiteAddress || !this.editLongitude || !this.editLatitude)) {
        return this.validAddress = false;
      }
      if(type === "add" && (!this.siteAddress || !this.longitude || !this.latitude)) {
        return this.validAddress = false;
      }
      return this.validAddress = true;
    },
    async callApiUpdateSiteConfig(site_id, data, config_type) {
      let prefixMessage = config_type === "tou" ? "Tou" : "Grid export";
      await multipleSitesService
        .updateSiteConfig(site_id, data)
        .then((res) => {
          if (res.status !== 200) throw res;
          let suffixMessage = "";
          if(config_type === "tou") {
            suffixMessage = res?.data?.data?.tou_enable ? 'enabled' : 'disabled';
          } else {
            suffixMessage = res?.data?.data?.grid_export ? 'enabled' : 'disabled';
          }
          this.apiMessage = `${prefixMessage} has been successfully ${suffixMessage}`;
          this.apiTitle = "Success";
          this.messageDialog = true;
          this.getMultipleSites();
        })
        .catch((err) => {
          let message = `${prefixMessage} cannot be updated`;
          if (err?.response?.data?.message) {
            message = err.response.data.message;
          }
          this.apiMessage = message;
          this.apiTitle = "Error";
          this.messageDialog = true;
        });
    },
    async handleChangeTou(item) {
      if (item?.site_config?.site_id) {
        const data = {
          tou_enable: item.site_config.tou_enable ? 0 : 1
        };
        await this.callApiUpdateSiteConfig(item.site_config.site_id, data, "tou");
      }
    },
    async handleChangeGrid(item) {
      if (item?.site_config?.site_id) {
        const data = {
          grid_export: item.site_config.grid_export ? 0 : 1
        };
        await this.callApiUpdateSiteConfig(item.site_config.site_id, data, "grid");
      }
    },
    openDialogFilterSite() {
      this.dialogFilterSite = true;
    },
    resetDialogFilterSite() {
      this.selectedGlobals = [];
      this.selectedRegionals = [];
      this.selectedAreas = [];
      this.selectedProjects = [];
      this.selectedInstalledDer = [];
      this.selectedSiteStatuses = [];
      this.commissionedDateRange = [];
      setTimeout(() => {
        this.resetMenuTree();
        this.$forceUpdate();
      }, 200);
    },
    closeDialogFilterSite() {
      this.dialogFilterSite = false;
    },
    openCommissionedDateMenu() {
      return this.commissionedDateMenu = !this.commissionedDateMenu;
    },
    closeCommissionedDateMenu() {
      if(this.commissionedDateRange && this.commissionedDateRange[0] && this.commissionedDateRange[1]){
        this.commissionedDateMenu = false;
      }else{
        this.commissionedDateMenu = true;
      }
      return this.commissionedDateMenu;
    },
    async submitFilter() {
      this.getSelectedMenuTreeValues();
      if(!(this.selectedGlobals?.length || this.selectedRegionals?.length || this.selectedAreas?.length || this.selectedProjects?.length || this.selectedInstalledDer?.length || this.selectedSiteStatuses?.length || this.commissionedDateRange?.length)) {
        await this.getMultipleSites();
      } else {
        let filters = {};
        if(this.selectedGlobals?.length) {
          filters.global = JSON.stringify(this.selectedGlobals);
        }
        if(this.selectedRegionals?.length) {
          filters.regional = JSON.stringify(this.selectedRegionals);
        }
        if(this.selectedAreas?.length) {
          filters.area = JSON.stringify(this.selectedAreas);
        }
        if(this.selectedProjects?.length) {
          filters.project = JSON.stringify(this.selectedProjects);
        }
        if(this.selectedInstalledDer?.length) {
          filters.installed = JSON.stringify(this.selectedInstalledDer);
        }
        if(this.selectedSiteStatuses?.length) {
          filters.status = JSON.stringify(this.selectedSiteStatuses);
        }
        if(this.commissionedDateRange?.length === 2) {
          filters.start_date = this.commissionedDateRange[0];
          filters.end_date = this.commissionedDateRange[1];
        }
        await this.getMultipleSites(filters);
      }
      this.closeDialogFilterSite();
    },
    addFields(arrayData) {
      arrayData.collapse = false;
      arrayData.isChecked = false;
      if (arrayData?.regionals?.length) {
        arrayData.regionals.forEach(regional => {
          this.addFields(regional);
          if (regional?.areas?.length) {
            regional.areas.forEach(area => {
              this.addFields(area);
              if (area?.projects?.length) {
                area.projects.forEach(project => {
                  this.addFields(project);
                });
              }
            });
          }
        });
      }
    },
    renderCollapseIcon(item) {
      item.collapse = !item.collapse;
      this.$forceUpdate();
    },
    handleCollapseChecked(item) {
      item.isChecked = true;
      this.$forceUpdate();
    },
    getSelectedMenuTreeValues() {
      this.listGlobalsFilter.forEach(globalItem => {
        if (globalItem.isChecked) {
          this.selectedGlobals.push(globalItem.name);
        }
        globalItem.regionals.forEach(regionalItem => {
          if (regionalItem.isChecked) {
            this.selectedRegionals.push(regionalItem.name);
          }
          regionalItem.areas.forEach(areaItem => {
            if (areaItem.isChecked) {
              this.selectedAreas.push(areaItem.name);
            }
            areaItem.projects.forEach(projectItem => {
              if (projectItem.isChecked) {
                this.selectedProjects.push(projectItem.name);
              }
            });
          });
        });
      });
    },
    addFieldsMenuTree() {
      this.listGlobalsFilter.forEach(country => {
        this.addFields(country);
      });
    },
    resetMenuTree() {
      this.listGlobalsFilter.forEach(globalItem => {
        globalItem.isChecked = false;
        globalItem.regionals.forEach(regionalItem => {
          regionalItem.isChecked = false;
          regionalItem.areas.forEach(areaItem => {
            areaItem.isChecked = false;
            areaItem.projects.forEach(projectItem => {
              projectItem.isChecked = false;
            });
          });
        });
      });
    },
    openDialogAddProject() {
      this.dialogAddProject = true;
    },
    closeDialogAddProject() {
      this.revertProject();
      this.dialogAddProject = false;
    },
    async submitAddProject() {
      if (this.$refs.addProjectForm.validate()) {
        const data = this.prepareDataProject(
          this.siteArea,
          this.projectName,
        );
        
        await multipleSitesService
          .storeProject(data)
          .then((res) => {
            if (res.status !== 200) throw res;
            this.getListGlobals();
            this.closeDialogAddProject();
            this.apiMessage = "Project has been successfully added";
            this.apiTitle = "Success";
            this.messageDialog = true;
          })
          .catch((err) => {
            let message = "Project cannot be added";
            if (err?.response?.data?.message) {
              message = err.response.data.message;
            }
            this.apiMessage = message;
            this.apiTitle = "Error";
            this.messageDialog = true;
          });
      }
    },
    prepareDataProject(area_id, project_name) {
      const data = {
        area_id: area_id,
        name: project_name,
      };
      return data;
    },
    revertProject() {
      this.$refs.addProjectForm.reset();
    },
    notAllowedActions () {
      return (!this.accessibleRoutes?.multi_site?.action || ['home_user', 'installer'].includes(this.user?.user_group?.type));
    },
    searchInstallers() {
      if (!this.searchTerm) {
        this.assignInstallersList = this.installersListCopy;
      } else {
        this.assignInstallersList = this.installersListCopy.filter((installer) => {
          if (installer.username && typeof installer.username === 'string') {
            return installer.username.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
          } else {
            return false;
          }
        });
      }
    },
    // searchCurrency() {
    //   if (!this.searchCurrencyTerm) {
    //     this.LIST_CURRENCIES = this.currenciesListCopy;
    //   } else {
    //     this.LIST_CURRENCIES = this.currenciesListCopy.filter((currency) => {
    //       if (currency.name && typeof currency.name === 'string') {
    //         return currency.name.toLowerCase().indexOf(this.searchCurrencyTerm.toLowerCase()) > -1;
    //       } else {
    //         return false;
    //       }
    //     });
    //   }
    // },
  },
};
</script>
